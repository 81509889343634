import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static targets = ["container",
    "topMarker", "bottomMarker",
    "startLoadingTop", "stopLoadingTop", "stopUnloadingTop", "startUnloadingTop",
    "startLoadingBottom", "stopLoadingBottom", "stopUnloadingBottom", "startUnloadingBottom"
  ]

  connect() {
    const loadingObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === this.topMarkerTarget) {
          if (entry.isIntersecting) {
            this.startLoadingTopTarget.click()
          } else {
            this.stopLoadingTopTarget.click()
          }
        } else if (entry.target === this.bottomMarkerTarget) {
          if (entry.isIntersecting) {
            this.startLoadingBottomTarget.click()
          } else {
            this.stopLoadingBottomTarget.click()
          }
        }
      })
    }, { rootMargin: "2000px 0 2000px 0" })

    loadingObserver.observe(this.topMarkerTarget)
    loadingObserver.observe(this.bottomMarkerTarget)

    const unloadingObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === this.topMarkerTarget) {
          if(!entry.isIntersecting) {
            this.startUnloadingTopTarget.click()
          } else {
            this.stopUnloadingTopTarget.click()
          }
        } else if (entry.target === this.bottomMarkerTarget) {
          if(!entry.isIntersecting) {
            this.startUnloadingBottomTarget.click()
          } else {
            this.stopUnloadingBottomTarget.click()
          }
        }
      })
    }, { rootMargin: "10000px 0 10000px 0" })

    unloadingObserver.observe(this.topMarkerTarget)
    unloadingObserver.observe(this.bottomMarkerTarget)
  }
}
