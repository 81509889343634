import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="window-search"
export default class extends Controller {
  static targets = ["prev", "input", "next"]

  next() {
    let query = this.inputTarget.value
    this.inputTarget.value = "" // otherwise we will find the query itself
    this.inputTarget.blur() // without this, we don't get the highlight of the search on the page
    window.find(query, false, false)
    this.inputTarget.value = query
  }

  keyup(event) {
    if (event.key == "Enter") {
      if (event.shiftKey) {
        this.prev()
      } else {
        this.next()
      }
    }
  }

  prev() {
    let query = this.inputTarget.value
    this.inputTarget.value = ""
    this.inputTarget.blur()
    window.find(query, false, true)
    this.inputTarget.value = query
  }
}
