import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "value" ]

  connect() {
    this.skipSave = false
  }

  edit() {
    this.valueTarget.style.display = "none"
    this.inputTarget.style.width = "" + this.inputTarget.parentElement.clientWidth + "px"
    this.inputTarget.style.display = "inline"
    this.inputTarget.focus()
  }

  keydown(event) {
    switch(event.key) {
      case "Escape":
        this.skipSave = true
        this.inputTarget.blur()
        break
      case "Tab":
        event.preventDefault()
        this.inputTarget.blur()

        if (event.shiftKey) {
          this.focusPrevious()
        } else {
          this.focusNext()
        }

        break
      case "Enter":
        this.inputTarget.blur()
        break
    }
  }

  blur(event) {
    this.save()

    this.valueTarget.style.display = "block"
    this.inputTarget.style.display = "none"
  }

  save() {
    if (this.skipSave) {
      this.skipSave = false
      return
    }

    this.valueTarget.innerHTML = this.inputTarget.value

    fetch(this.data.get("productUrl") + "?authenticity_token=" + encodeURIComponent(document.querySelector("meta[name=csrf-token]").content), {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "PATCH",
      body: JSON.stringify({ product: { shop_stock: this.inputTarget.value }})
    }).then(response => response.status).then(function (status) {
      if (status != 200) {
        alert("Error kurwa!")
      } else {
        console.log("OK")
      }
    }.bind(this));
  }

  focusNext() {
    const allStockControllers = Array.from(document.querySelectorAll("[data-controller='shop-stock']"))

    const thisIndex = allStockControllers.indexOf(this.element)
    const nextIndex = (thisIndex + 1) % allStockControllers.length

    allStockControllers[nextIndex].querySelectorAll("[data-shop-stock-target='value'")[0].click()
  }

  focusPrevious() {
    const allStockControllers = Array.from(document.querySelectorAll("[data-controller='shop-stock']"))

    const thisIndex = allStockControllers.indexOf(this.element)

    var nextIndex

    if (thisIndex > 0) {
      nextIndex = thisIndex - 1
    } else {
      nextIndex = allStockControllers.length - 1
    }

    allStockControllers[nextIndex].querySelectorAll("[data-shop-stock-target='value'")[0].click()
  }
}
