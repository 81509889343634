import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="network-errors-detector"
export default class extends Controller {
  connect() {
    document.addEventListener("turbo:before-fetch-request", this.startRequestTimer) // kliki i formularze
    document.addEventListener("turbo:before-stream-render", this.stopRequestTimer)  // updaty stream
    document.addEventListener("turbo:frame-render",         this.stopRequestTimer)  // modal turbo-frame
    document.addEventListener("turbo:visit",                this.stopRequestTimer)  // visit
  }

  disconnect() {
    document.removeEventListener("turbo:before-fetch-request", this.startRequestTimer)
    document.removeEventListener("turbo:before-stream-render", this.stopRequestTimer)
    document.removeEventListener("turbo:frame-render",         this.stopRequestTimer)
    document.removeEventListener("turbo:visit",                this.stopRequestTimer)
    clearTimeout(this.timeoutId)
  }

  startRequestTimer(e) {
    clearTimeout(this.timeoutId)

    this.timeoutId = setTimeout(() => {
      alert("Wystąpił błąd połączenia z serwerem. Spróbuję odświeżyć stronę.")
      window.location.reload()
    }, 5000)
  }

  stopRequestTimer(e) {
    clearTimeout(this.timeoutId)
  }
}
