import "chartkick/chart.js"
import Sortable from 'sortablejs';
window.Sortable = Sortable;

import Rails from "@rails/ujs"
Rails.start()

import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "./controllers"
