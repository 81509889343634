import { Controller } from "@hotwired/stimulus"

// Two numbers like  (todo) "3" and  (done) "0", and two buttons + and -
// Prevents from todo and done going below 0.

// Connects to data-controller="two-numbers"
export default class extends Controller {
  static targets = ["todoQuantity", "doneQuantity"]

  static values = {
    todoQuantity: { type: Number, default: Infinity }, // todoQuantity is optional
    doneQuantity: Number,
    checkTodoQuantity: { type: Boolean, default: true },
  }

  increaseDoneQuantity(event) {
    if (this.todoQuantityValue > 0 || !this.checkTodoQuantityValue) {
      this.todoQuantityValue -= 1
      this.doneQuantityValue += 1
    } else {
      event.preventDefault()  // Prevent GET on the link
      event.stopPropagation() // Disable PATCH by UJS
    }
  }

  decreaseDoneQuantity(event) {
    if (this.doneQuantityValue > 0) {
      this.doneQuantityValue -= 1
      this.todoQuantityValue += 1
    } else {
      event.preventDefault()  // Prevent GET on the link
      event.stopPropagation() // Disable PATCH by UJS
    }
  }

  doneQuantityValueChanged() {
    this.doneQuantityTarget.innerHTML = this.doneQuantityValue
  }

  todoQuantityValueChanged() {
    if(this.hasTodoQuantityTarget) {
      this.todoQuantityTarget.innerHTML = this.todoQuantityValue
    }
  }
}
