import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "filtered", "checkbox" ]

  connect() {
    this.checkboxTarget.addEventListener('change', this.filter.bind(this))
  }

  filter(event) {
    this.filteredTargets.forEach(target => {
      target.classList.toggle('hidden', event.target.checked)
    })
  }

  filteredTargetConnected(target) {
    target.classList.toggle('hidden', this.checkboxTarget.checked)
  }
}
