import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    open: Boolean
  }

  static targets = [ "content", "openButton", "closeButton" ]

  connect() {
    this.contentTarget.addEventListener("transitionend", this.onTransitionEnd.bind(this))
  }

  // can't animate h-auto etc. we need exact height. negative marginTop slides the content up nicely
  openValueChanged(current, previous) {
    if (this.openValue) {
      this.openButtonTarget.classList.add("hidden")
      this.closeButtonTarget.classList.remove("hidden")
      this.contentTarget.style.display = "block"
      // make sure we have display: block before we start animating
      requestAnimationFrame(() => {
        this.contentTarget.style.transform = "translateY(0)"
      })
    } else {
      this.openButtonTarget.classList.remove("hidden")
      this.closeButtonTarget.classList.add("hidden")
      this.contentTarget.style.transform = "translateY(-" + this.contentTarget.scrollHeight  + "px)"
    }
  }

  toggle() {
    this.openValue = !this.openValue
  }

  onTransitionEnd(event) {
    if (this.openValue) {
      this.contentTarget.style.transform = "" // clear transform so the image blurred background is not constrained to the transform animation layer
    } else {
      this.contentTarget.style.display = "none" // we hide at the end to avoid leftover paddings
    }
  }
}
