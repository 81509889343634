import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // Accepts only images
  // Sends relative paths to the server
  filesChosen(event) {
    const dt = new DataTransfer();

    Array.from(event.target.files).forEach((file) => {
      if (file.type.match(/image.*/)) {
        dt.items.add(file);

        const input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", "mv_import[image_paths][]");
        input.setAttribute("value", file.webkitRelativePath);
        this.element.form.appendChild(input);
      }
    });

    this.element.files = dt.files;
    this.element.form.requestSubmit();
  }
}
