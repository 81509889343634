import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="inline-editor"
export default class extends Controller {
  static targets = ["form", "input", "value"]

  edit() {
    this.valueTarget.classList.add("hidden")
    this.formTarget.classList.remove("hidden")
    this.inputTarget.focus()
    this.inputTarget.select()
  }

  update(e) {
    Rails.fire(this.formTarget, "submit")
  }

}
