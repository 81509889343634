import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  static targets = ["modal"]

  hideModal() {
    if (this.busy) {
      return
    }

    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }

  // disable hiding the modal during form submission. Prevents flickering submitting forms on focus lost by clicking outside of modal.
  // action: "turbo:submit-start->turbo-modal#submitStart"
  submitStart(e) {
    this.busy = true
  }

  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    this.busy = false
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.modalTarget.contains(e.target)) {
      return
    }

    this.hideModal()
  }
}
