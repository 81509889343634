import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="uv-filter"
export default class extends Controller {
  static targets = [ "uvPrintout" ]

  connect() {
  }

  toggle(event) {
    const id = event.target.id
    const value = event.target.checked

    this.uvPrintoutTargets.forEach((target) => {
      if (target.dataset["tile-size"] == id) {
        if (value) {
          target.classList.remove("hidden")
        } else {
          target.classList.add("hidden")
        }
      }
    })
  }
}
